import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { InternalLink, ExternalLink, EmailLink } from "../components/sitelinks";
import {
    MainContainer,
    BoxContainer,
    BoxHeading,
    SubHeading,
    BoxCopy
} from "../components/simplepagelayout";

const WarningDiv = styled.div`
    border-style: solid;
    border-color: red;
    border-width: 1px;
    padding: 1em;
    margin-bottom: 1em;
`;

const SoftwarePage = () => (
        <Layout>
        <SEO title= "Software" />
        <MainContainer>
          <BoxContainer>
        <BoxHeading>
        Download Contour Rig Tools v1.0.11.1
        </BoxHeading>
        <BoxCopy>
        <p>
         <div>
            <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_windows_maya2020_2024.zip">Download Rig Tools for Windows</ExternalLink>
         </div>
         <div>
            <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_linux_maya2020_2024.tgz">Download Rig Tools for Linux</ExternalLink>
         </div>
         <div>
            <ExternalLink href="https://downloads.notionalpipe.com/releases/v1_0_11_1/Contour_v1.0.11.1_macOS_maya2020_2024.zip">Download Rig Tools for macOS</ExternalLink>
         </div>
        </p>

        <p>Contour Rig Tools runs in either animator mode or rigger mode, using the same plug-in, and includes a free 30-day trial of rigger mode.</p>
        <p>Animator mode is always free of charge, and allows animating using any Rig Tools rig.
        Only creating Contour rigs requires purchasing a license.</p>
        <p>Contour currently supports Maya 2020 through Maya 2024. Only the latest update of each version is officially supported, although Contour may work with earlier updates. We do not support Maya 2023.0, Maya 2023.1, or Maya 2024.0. If you are running Maya 2023, it must be Maya 2023.2 or later. If you are running Maya 2024, it must be Maya 2024.1 or later.</p>

        {/* For some reason we need to encase WarningDiv in an extra div,
            otherwise it'll disappear when the user reloads the page */}
        <div>
            <WarningDiv>
               <p><strong>Important Note</strong></p>

               <p>
            There are a number of issues that can affect rigs made with Contour when Cached Playback is enabled. These appear to be bugs in Maya's implementation, and we have filed bug reports about them with Autodesk.
            </p>

               <div>
            Fortunately, there are fairly straightforward workarounds for them. We recommend reading the entire <ExternalLink href="https://manuals.notionalpipe.com/rigtools/11-caveats/11-caveats.html">Caveats</ExternalLink> section of the manual, but if you animate with Cached Playback enabled, we strongly recommend reading <ExternalLink href="https://manuals.notionalpipe.com/rigtools/11-caveats/11-caveats.html#issues-related-to-cached-playback">the section related to Cached Playback.</ExternalLink>
               </div>
            </WarningDiv>
        </div>

        <p>
           <div>
              <InternalLink to="/contourpatchnotes">See the Contour Rig Tools patch notes</InternalLink>
           </div>

           <div>
              <InternalLink to="/contourversions">Download previous versions of Rig Tools</InternalLink>
           </div>
        </p>
        </BoxCopy>
          </BoxContainer>

        <BoxContainer id="purchase">
        <BoxHeading>
        Purchase Contour Rig Tools
    </BoxHeading>
        <BoxCopy>
        <p>Contour Rig Tools is offered on a subscription basis. We&lsquo;re still working on our automated subscription management and payment systems, but for the time being Rig Tools subscriptions can be purchased by arrangement. If you'd like to purchase Rig Tools email us at <EmailLink href="mailto: sales@notionalpipe.com">sales@notionalpipe.com</EmailLink>.</p>

        </BoxCopy>
        <SubHeading>
           Pricing
        </SubHeading>
        <BoxCopy>
        <SubHeading>
           Animator Mode: FREE
        </SubHeading>
           <p>Contour Rig Tools animator mode is always free. This means that rigs you create with Contour's
    rigger mode can be animated by any number of animators, free of charge. Animator mode allows animating with Contour rigs, and adding bone cvs to those rigs as needed.</p>

        <SubHeading>
           Rigger Mode
        </SubHeading>
          <p>Contour Rig Tools rigger mode subscriptions can be purchased as either node-locked licenses or floating licenses. Rigger mode activates all of the features needed to create Contour rigs. The pricing is the same for node-locked and floating licenses:</p>
<ul>
<li>$50.00 / month for one month</li>
<li>$45.00 / month for six months</li>
<li>$40.00 / month for twenty-four months or more</li>
</ul>
        <p>The discounts for six months or more can be combined over more than one license. That is to say, the discount for twenty-four months would also apply to a year-long subscription for two seats.</p>

        <p>Note that paid licenses are not necessary to animate or render with Rig Tools rigs, only to create and modify them.</p>
        </BoxCopy>
        </BoxContainer>
        <BoxContainer>
        <BoxHeading>
           Other Services
        </BoxHeading>
        <BoxCopy>
          <p>
            If your company is interested in making other licensing arrangements, interested in training, premium support or custom development, or has other needs Notional Pipe can meet, email us at <EmailLink href="mailto: business@notionalpipe.com">business@notionalpipe.com</EmailLink>.
          </p>
        </BoxCopy>
        </BoxContainer>

        </MainContainer>
    </Layout>
);

export default SoftwarePage;
